
.html {
    background-color: red;
}

.cs-img-tag {
    //background-size: contain;
    background-repeat: no-repeat;
}

.buy-now-logo {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    color: #91877B;
    font-size: 25px;
}

.card-and-more {
    max-height: 495px;
    min-height: 100px;
    margin-top: 35%;
    margin-bottom: 35%;
    height: auto;
    text-align: center;
    font-size: 128px;
    color: #91877B;
}

.mouse-over-display {
    margin-right:20px;
}